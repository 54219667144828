


















































































































































































































































































































































































































































































































































































































































































































@import '../../assets/css/global.css';
@import "../../assets/css/antd.css";
.cell_health_chart {
	width: 100%;
	height: 700px;
	/* overflow: hidden; */
	/* background: #000; */
	margin-top: 10px;	
	padding-top: 10px;
}
